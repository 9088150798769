var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: "mdi-home-import-outline",
        title: "Alocação de Ambientes"
      },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.showDates
                ? _c("span", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(_vm.date.start)) + " - "
                      )
                    ]),
                    _c("span", [
                      _vm._v(" - " + _vm._s(_vm._f("formatDate")(_vm.date.end)))
                    ])
                  ])
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(4, "type=ambience")
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Horário dos Ambientes ")])]
              ),
              _c("sys-ambience-pop-over", {
                attrs: { configs: _vm.config },
                on: {
                  "update:configs": function($event) {
                    _vm.config = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loading } }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { height: "100px", focusable: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: {
                                "disable-icon-rotate": _vm.ambience != null
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _vm.ambience
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ma-1",
                                                                attrs: {
                                                                  disabled: !_vm.ambience,
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "red"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.clearAmbience()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-eraser "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3055146346
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(" Liberar ambiente ")
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _c("strong", { staticClass: "primary--text" }, [
                                  _vm._v("Ambientes")
                                ]),
                                _c("br"),
                                _vm.ambience
                                  ? _c("small", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAmbienceDescription(
                                              _vm.ambience
                                            )
                                          )
                                        )
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        " Capacidade: " +
                                          _vm._s(_vm.ambience.capacity) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto elevation-0",
                                  staticStyle: {
                                    "overflow-y": "scroll",
                                    "max-height": "400px"
                                  }
                                },
                                [
                                  _c("sys-ambience-tree-view", {
                                    attrs: { loadData: "" },
                                    on: { select: _vm.selectAmbience }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: {
                                "disable-icon-rotate": _vm.group != null
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _vm.group
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ma-1",
                                                                attrs: {
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "red"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.clearGroup()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-eraser "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                4010927250
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " Liberar ambiente da turma "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _c("strong", { staticClass: "primary--text" }, [
                                  _vm._v("Turmas")
                                ]),
                                _c("br"),
                                _vm.group
                                  ? _c("small", [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.group.name) + " ")
                                      ]),
                                      _vm.group.students
                                        ? _c("span", [
                                            _c("br"),
                                            _vm._v(
                                              "Máximo Alunos: " +
                                                _vm._s(_vm.students) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto elevation-0",
                                  staticStyle: {
                                    "overflow-y": "scroll",
                                    "max-height": "400px"
                                  }
                                },
                                [
                                  _c("sys-group-tree-view", {
                                    attrs: { allowDrag: "", showGrids: "" },
                                    on: {
                                      select: _vm.selectGroup,
                                      start: function($event) {
                                        _vm.itemDrop = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8" } },
                [
                  _c("sys-ambience-calendar", {
                    attrs: {
                      model: _vm.ambience,
                      loading: _vm.loadingAmbience,
                      date: _vm.date,
                      start: _vm.workTime.classes_start
                    },
                    on: {
                      drop: _vm.endAmbience,
                      remove: _vm.removeAllocation,
                      "update:date": function($event) {
                        _vm.date = $event
                      }
                    }
                  }),
                  _c("sys-ambience-hours", {
                    attrs: {
                      renderComponent: _vm.renderComponent,
                      loading: _vm.loadingGroup,
                      type: "group",
                      item: _vm.group
                    },
                    on: {
                      remove: _vm.removeClasse,
                      start: function($event) {
                        _vm.itemDrop = $event
                      },
                      dblclick: _vm.loadAmbience
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emc-alert-snackbar", {
        attrs: {
          show: _vm.error,
          timeout: 20000,
          errors: _vm.errors,
          color: "error"
        }
      }),
      _c("sys-ambience-search", {
        attrs: { show: _vm.showFormSearch },
        on: {
          "update:show": function($event) {
            _vm.showFormSearch = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }